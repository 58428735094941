module.exports = {
    language: {
        name: 'English'
    },
    // version2
    v2headline1: {
        name: '关于乐团',
        suwentou: "苏文投集团",
        intro: '乐团简介',
        orchestraLeader: '乐团领导',
        artisticConsultant: '艺术顾问',
        artist: {
            name: '乐团艺术家',
            majordomo: '彭家鹏',
            direction: '朱昌耀',
            bandsman: '乐手'
        },
        ExecutiveManagementTeam: '经营管理团队',
    },
    v2headline2: {
        name: '乐团演出',
        musicSeason: '音乐季',
        internationalTour: '国际巡演',
        weiYaoWorks: '委约作品'
    },
    v2headline3: {
        name: '乐团资讯',
        chronicleOfEvents: '大事记',
        orchestraNews: '乐团新闻'
    },
    v2headline4: {
        name: '艺术教育',
        artTraining: '艺术培训',
        SocialArtLevelExamination: '社会艺术水平考级',
        saishizhanyan: '赛事&展演',
    },
    v2headline5: '音乐厅/演出购票',
    v2headline6: {
        name: '联系我们',
        joinus: '加入我们',
        businessCooperation: '商务合作',
        concertHallGuide: '音乐厅导览',
        parkingGuidance: '停车引导',
        LoanSheetMusic: '租借乐谱',
    },
    v2headline7: "国家公共文化云",
    v2headline8: "艺术团队联合会",
    // version2 end
    footer: {
        name: '联系我们',
        site: '江苏省苏州市高新区太湖大道999号步青东路',
        company: '©2017苏州民族管弦乐团有限公司. all rights reserved.',
        record: '苏ICP 备17070606号-1',
        other1: '增值电信业务经营许可证  苏B2-20210931',
        other2: '网络文化经营许可证 苏网文(2022)0379-024号',
        OfficialAccount: '官方账号',
        sponsor: "我们的合作伙伴"
    },
    // 艺术家
    artistObj: {
        level1: "<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>彭家鹏</b>（艺术总监兼首席指挥），国家一级指挥，享受国务院政府津贴。第十三、十四届全国政协委员，第76届国际青年音乐联盟执委。中国广播民族乐团、中国歌剧舞剧院、澳门中乐团（2003—2016）、河南交响乐团、中国东方交响乐团、东方中乐团艺术总监兼首席指挥；乌克兰国家交响乐团、奥地利萨尔茨堡莫扎特交响乐团、奥地利格拉兹交响乐团、韩国釜山国立国乐管弦乐团、捷克国家交响乐团的常任客席指挥；2017年6月起任苏州民族管弦乐团艺术总监兼首席指挥。<br>"+
        "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;彭家鹏毕业于中央音乐学院指挥系，获硕士学位，是第35届国际康德拉申指挥大师班奖唯一的亚洲获奖指挥家，并以第一名的成绩完成国际指挥大师班（乌克兰基辅）的学业。曾获中国金唱片指挥特别奖、国家“四个一批”人才、中国十大杰出青年等荣誉。自2000年起连续13年在维也纳金色大厅指挥世界各大乐团，引起巨大轰动，奥地利《信报》称他为“兼有日本小泽征尔和意大利指挥大师穆蒂的指挥风范”，奥地利最具权威的音乐评论家辛科·卫奇评价他“生机盎然、热情洋溢、手部动作快而线条优美，具有独特的风格”。<br>"+
        "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2013年他远赴以培养世界大师级指挥而闻名的维也纳音乐表演艺术大学深造欧洲歌剧，获得导师康阿德·莱茵特纳教授评语：“彭家鹏先生有着非凡的才华、高度的敏锐和细腻的乐感，这使他的指挥艺术臻于完美”。2021年荣膺奥地利音乐剧院奖·国际音乐文化成就奖。</p>",
        level2: "<p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>朱昌耀</b>（艺术指导），国家一级演奏员，著名二胡演奏家、作曲家，中国民族弓弦乐学会副会长、中国民族管弦乐学会胡琴专业委员会副会长、江苏省音协名誉主席、江苏省演艺集团艺术指导委员会主任，中国音乐学院、南京艺术学院等多所高等专业院校的客座教授。1991年被国务院授予“政府特殊津贴”，2015年获紫金文化奖章。曾多次赴欧美、东南亚的国家及台湾、香港和澳门等地区访问演出，获得了国内外观众的高度称赞，被誉为“如杯中醇酒，满而不溢”“弓弓诉人意，弦弦道世情”“世界一流的弦乐演奏家”“人间国宝”。2017年6月起担任苏州民族管弦乐团艺术指导。</p>"
    },
    newMedia: {
        introduce: '苏州民族管弦乐团利用数字技术为代表的新媒体，打破了传统媒介之间的壁垒，使传统民族交响乐的表现形式更加丰富生动。将文字、音频、画面融为一体，借助新媒体广受年轻受众群体喜爱，交互性极强的特点，做到即时地、无限地扩展内容，使得传统民族乐传播更加迅速和生动，更加容易被目标大众所接受',
        trill: '抖音',
        orchestra: '乐团新媒体',
        trackName: '曲目名称',
        composer: '作曲家',
        player: '演奏者'
    },
    training: {
        art1: '艺术点亮梦想',
        art2: '教育成就未来',
        art3: '苏州民族管弦乐团传承吴地芳泽，融合古琴昆韵，',
        art4: '在高山流水的小龙山畔，起弦而歌，觅候佳音。',
        centre: '中心简介',
        introduc: '苏州民族管弦乐团社会培训部(苏州新乐府艺术培训中心有限公司),为苏州民 族管弦乐团全资子公司，致力于青少年民族音乐教育，通过传播中华优秀文化， 繁荣江南丝竹艺术，从而引导青少年用传统民族艺术语言讲好华夏故事、弘扬民 族精神。依托苏州民族管弦乐团专家指导以及优秀青年演奏家的人才优势，着力 打造集“权威师资、标准课程、多元实践、惠民价格”为一体的艺术教育优质品牌。',
        trainee: '学员风采',
        mien: '苏州民族管弦乐团是由苏州市和高新区共同成立的职业乐团，为市级公益性文艺团体，旗下“苏州新乐府艺术培训中心”（以下简称中心）作为艺术培训、活动策划、文化传媒领域全新品牌',
        teacher: '教师风采',
        // teacherStyle:'苏州民族管弦乐团是由苏州市和高新区共同成立的职业乐团，为市级公益性文艺团体，旗下“苏州新乐府艺术培训中心”（以下简称中心）作为艺术培训、活动策划、文化传媒领域全新品牌',
        socialArt: '社会艺术水平考级（2025）',
        Fees: "收费标准",
        smyk: "扫码约课",
        examination: '考级报名',
        examinationTwo:'展演报名',
        info: '准考证查询下载',
        info2: '参赛证下载',
        info3: '成绩查询',
        info4: '考级成绩查询',
        showEvent: '第四届苏州市青少年民族器乐展演',
        game: '比赛名称',
        curriculum: '课程设置',
        profession1: '音乐启蒙',
        profession2: '器乐培训',
        profession3: '艺术考学',
        profession4: '成人器乐',
        profession5: '(4-6 岁)',
        profession6: '(1-10 级/6-12 岁)',
        profession7: '(初中学段/高中学段)',
        profession8: '(18 周岁以上)',
        profession9: '竹笛',
        profession10: '大提琴',
    },
    join: {
        name: '乐团简介',
        introduction1: '苏州民族管弦乐团是苏州市和苏州高新区政府共同主办的公益性专业职业乐团，为市级文艺团体，是在市工商局注册登记的独立法人单位,由彭家鹏先生担任艺术总监兼首席指挥,朱昌耀先生担任艺术指导,赵季平、刘锡津、顾冠仁、王书伟担任艺术顾问。',
        introduction2: '建团至今，乐团已在北京、上海、南京、西安、杭州等城市及美国、德国、比利时、意大利、瑞士、列支敦士登、奥地利、匈牙利、波兰、俄罗斯、日本、韩国等十多个国家举办了一系列大型音乐会，得到中外专家和观众的广泛好评。被誉为：将民族音乐与国际表达完美融合，体现了先进的艺术理念与精湛的演奏技艺，是一支高起点、高标准、高水平的“国家级青年艺术团队”！',
        introduction3: '乐团职能包括承担公益性活动和提供市场演出两部分。其中，公益性活动主要为：传承和弘扬苏州、江南和中国民族民间音乐经典；创作和演出民族管弦乐精品；参加海内外赛事和重要交流演出；举行惠民系列活动；指导和服务公共文化创作、演出和人才的培养。市场演出活动包括：举办年度“音乐季”各类音乐会；组织参与各类社会活动、演出以及社会培训等。',
        orchestra: '乐团职能',
        recruitment: '招聘信息',
        positionTitle: '招聘岗位名称',
        time: '时间',
        location: '地点',
        jobdescription: '岗位描述',
        // description1:'苏州民族管弦乐团是由苏州市和高新区共同成立的职业乐团，为市级公益性文艺团体。乐团由曾经十三次赴维也纳金色大厅指挥的著名指挥家彭家鹏任艺术总监兼首席指挥，由著名二胡演奏家、作曲家朱昌耀任艺术指导。聘请中国音乐家协会名誉主席、著名作曲家赵季平，中国民族管弦乐学会会长、著名作曲家刘锡津，中国民族管弦乐学会副会长兼秘书长王书伟，中国民族管弦乐学会荣誉会长、著名作曲家顾冠仁任艺术顾问。乐团拥有演奏员九十余人，由海内外知名演奏家及中央、上海等九大音乐学院的优秀毕业生组成。',
        remuneration: '薪酬待遇',
        delivery: '投递简历',
        benefits: '其他福利',
        welfare: '苏州民族管弦乐团是由苏州市和高新区共同成立的职业乐团，为市级公益性文艺团体。乐团由曾经十三次赴维也纳金色大厅指挥的著名指挥家彭家鹏任艺术总监兼首席指挥，由著名二胡演奏家、作曲家朱昌耀任艺术指导。',
        right: '本招聘简章最终解释权归苏州民族管弦乐团有限公司所有',
        examInformation: '考试信息',
        ei1: '考试内容',
        ei2: '1.初试:',
        ei3: '提供本人演奏的8分钟左右乐曲视频（无伴奏、无剪辑）电子档',
        ei4: '2.复试：',
        ei5: '（1）2首不同风格且能反映应试者最高水平的作品',
        ei6: '（2）24个大小调音阶（小调为和声小调）',
        ei7: '（3）现场视奏作品',
        ei8: '（4）乐队困难片段（复试前20天通知初试通过者）',
        rg1: '报名方式',
        rg2: '1、网上报名：',
        rg3: '将报名表、个人证件照、本人有效身份证、简历、学历证明、获奖证书、本人演奏的8分钟左右乐曲视频（无伴奏、无剪辑）等电子档存于一个文件夹后压缩发送至指定邮箱：zhouyong@scidg.com.cn（邮件标题注明“苏州民族管弦乐团-应聘岗位-姓名”）；',
        rg4: '2、报名表下载地址：',
        rg5: 'https://pan.baidu.com/s/1s2XN-ZQYOBFAIPfeUtarUg?pwd=q7vk',
        rg6: '3、联系电话：',
        rg61: '0512-69580985',
        rg7: '点击下载',
        se1: '复试时间、地点',
        se2: '1、时间：另行通知',
        se3: '2、地点：苏州民族管弦乐团音乐厅（苏州市高新区太湖大道999号）',
        
    },
    introductory: {
        title1: '<b>苏州民族管弦乐团</b>是苏州市级职业文艺院团，为苏文投集团旗下全资子公司，由著名指挥家彭家鹏任艺术总监兼首席指挥，著名二胡演奏家、作曲家朱昌耀任艺术指导。乐团聘请著名作曲家赵季平、刘锡津、顾冠仁及民族音乐活动家王书伟任艺术顾问。乐团拥有演奏员九十余人，由海内外知名演奏家及毕业于中央、上海等十大音乐学院的优秀人才组成。建团至今，乐团先后获得全国文旅系统先进集体，全国十佳民族管弦乐团，奥地利音乐剧院奖·国际交响乐团奖，文旅部全国民族器乐展演优秀民族乐团，国家、省艺术基金项目，江苏省文华大奖，江苏省舞台艺术精品创作扶持工程重点投入剧目，江苏省文明单位等荣誉。乐团先后委约当代著名作曲家创作新作品，其中七部作品入选文旅部“时代交响——中国交响音乐作品创作扶持计划”；一批优秀个人获中国音乐金钟奖等荣誉。乐团已在北京、上海等国内城市及16个国家的32个城市举办了一系列大中型音乐会，得到中外观众的广泛认可和一致好评。中宣部、文旅部、中国文联、中国音协有关领导，国内外著名专家学者，以及新华社、人民日报、中央电视台、俄罗斯国家电视台等诸多媒体称赞乐团，将民族音乐与国际表达完美融合，体现了先进的艺术理念与精湛的演奏技艺，是一支高起点、高标准、高水平的“国家级青年艺术团队”！',
        title2: '',
        title3: '乐团力争通过长期不懈地努力，打造具有良好艺术修养、优秀表演能力的人才高地，在传承中华优秀传统文化的基础上，积极进行民族音乐当代气质和国际表达的探索和实践，逐步形成具有苏州品质、江南风格、中国气派的民族音乐品牌，成为苏州文化向全国乃至海内外传播交流的主力军、满足广大人民群众精神文化需求的生力军。',
    },
    bandsman: {
        seeDetails: '点击查看详情',
        dropDown: '下拉查阅乐手详情'
    },
    show: {
        time: '演出时间',
        venue: '演出场馆',
        guest: '演出嘉宾'
    },
    producDeta: {
        commission: '委约作品',
        title: '近两年来，苏州民族管弦乐团注重“委约创作”制度，以弘扬时代主旋律为主题，兼具国际化艺术表现形式与水准，在中华优秀传统文化、江苏与苏州历史文化积淀中汲取养分，体现中国气派、江南风雅和苏州韵味，用民族管弦乐的创新理念引领艺术建设，探索出一条既体现民族特色，又符合国际审美的创新之路。'
    },
    business: {
        title: '苏州民族管弦乐团为传承和弘扬中国民族民间音乐，高质量排演中国民族音乐的经典作品和海内外优秀作曲家委约创作的民族优秀音乐作品，积极像人民大众推广名族音乐，推动中国名族管弦乐和中国音乐的繁荣发展，作为优秀文化艺术的展示窗口和交流平台，我们热切期待关心艺术事业发展的社会各界，开展全方位多层次的合作与交流，打造具有良好艺术修养、优秀表演能力的人才高地，传承中华优秀传统文化。',
        contact: '联系我们',
        location: '江苏省苏州市高新区太湖大道999号步青路东'
    },
    showDetail: {
        time: '演出时间',
        venue: '演出场馆',
        guest: '演出嘉宾',
        child: '儿童入场提示',
        prohibit: '禁止携带的物品说明',
        duration: '演出时长',
        admission: '入场时间',
        deposit: '寄存说明',
        introduction: '演出介绍'
    },
    information: {
        text1: '2018年 美国展演',
        text2: '2019年 欧洲巡演',
        text3: '2019年 日本巡演',
        text4: '点击下载',
    },
    publicBenefit: {
        concert: {
            text1: '中型音乐会',
            text2: '苏州民族管弦乐团中型音乐会由乐团艺术指导朱昌耀领衔，对传统民族音乐进行传承与创新，旨在弘扬中国民族音乐文化，丰富市民精神文化生活，培养中小学生的审美情趣与艺术修养，助推文化建设的发展与城市文化品味的提升。已推出《国乐戏韵》、《唐宋古韵咏江南》、 《弓弦情话》、《弹拨声声总是情》、《中华情 江南韵》等多套深受大众喜爱的音乐会。'
        },
        forum: {
            text1: '“音乐大师·名家讲坛”导赏音乐会',
            text2: '“音乐大师•名家讲堂”系列活动旨在丰富苏州公共文化服务板块内容，提升民族音乐爱好者艺术素养，搭建起一个可满足广大人民群众对民族音乐精神文化需求的公益性平台。让群众在欣赏民族音乐之美的同时，加深对传统音乐文化的认知，不断增强人民群众的民族自豪感。'
        },
        school: {
            text1: '进社区进校园',
            text2: '苏州民族管弦乐团自2018年开启“高雅艺术进校园”、“高雅艺术进乡镇”系列演出活动以来，以弘扬民族音乐文化为己任，将苏州市区及各区（市）乡镇、学校作为主阵地，举办“高雅艺术进基层”文化惠民公益演出130场、“高雅艺术进校园”民族音乐普及演出178场，赢得了老百姓和学生们的喜爱。'
        },
    },
    home: {
        internationalTour: '国际巡演',
        internationalTourSub: '将中华民族音乐之美传向世界',
        ine1: '2018年 美国展演',
        int1: '2018年是苏州市与美国波特兰市结为友好城市30周年，一曲热烈欢腾的《庆典序曲》,锣鼓、唢呐......',
        ine2: '2019年 欧洲巡演',
        int2: '2022年9月，苏州民族管弦乐新一轮的国际巡演即将启程，这次的巡演将在9个城市举行，期待届时......',
        ine3: '2019年 日本巡演',
        int3: '2019年9月，为庆祝新中国成立70周年，纪念中日邦交正常化47年，应苏州友好城市日本金泽政府......',
        ine4: '2019年 韩国巡演',
        int4: '为庆祝新中国成立70周年，纪念中韩建交27周年，进一步推动苏州文化“走出去”，应韩国音乐协会......',
        ine5: '2023年 欧洲巡演',
        int5: '苏州民族管弦乐团赴斯洛文尼亚、德国、奥地利3国7城，为欧洲观众带来“来自苏州的声音”',
        ine6: '2024年 日本巡演',
        int6: '2024年1月4日-7日，苏州民族管弦乐团赴日本名古屋，参与第18届名古屋迎中国新春活动',
        digitalConcertHall: '数字音乐厅',
        publicBenefit: '公益惠民 系列演出',
        brandActivity: '品牌活动',
        global: '第74届青年联盟代表大会',
        musicExhibition: '首届江苏·苏州民族音乐展演',
        news: '新闻资讯',
        suzhou: '苏州民族管弦乐团',
        wait: '点击进入',
        details: '了解详情',
        viewMore: "点击阅读更多"
    },
    showHeader: {
        oz: '欧洲巡演',
        hg: '韩国巡演',
        rb: '日本巡演',
        mg: '美国巡演',
        oz2: '2023年 欧洲巡演',
        rb2: '2024年 日本巡演',
    }
}